var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('table-header',{attrs:{"search":"","filter":_vm.filter,"search-updated":_vm.getComments,"date":""}}),_c('b-table',{ref:"commentsTable",attrs:{"striped":"","items":_vm.comments,"responsive":"","fields":_vm.tableFields,"primary-key":"id","show-empty":"","empty-text":_vm.$t('page.students.search.labels.no-items'),"sort-by":_vm.filter.sortBy,"sort-desc":_vm.filter.sortDesc},on:{"update:sortBy":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"cell(user)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-center"},[(data.item.user.image)?_c('store-image',{attrs:{"image-url":data.item.user.image.full_url,"wrapper-class":"table-avatar"}}):_c('b-avatar',{attrs:{"size":"25px"}}),_c('span',{staticClass:"d-block ml-1"},[_vm._v(_vm._s(data.value))])],1)]}},{key:"cell(actions)",fn:function(ref){
var toggleDetails = ref.toggleDetails;
var detailsShowing = ref.detailsShowing;
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":toggleDetails}},[_c('feather-icon',{attrs:{"size":"15","icon":detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'}})],1),_c('b-dropdown',{attrs:{"size":"sm","variant":"flat-secondary","toggle-class":"text-decoration-none btn-icon rounded-circle ml-1","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"size":"15","icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":toggleDetails}},[_vm._v(" Подробнее ")]),_c('b-dropdown-item',{attrs:{"to":{name: 'cabinet.step', params: { step_id: item.lesson_step_id }}}},[_vm._v(" Открыть карточку ")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteComment(item.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Удалить")])],1)],1)],1)]}},{key:"row-details",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
return [_c('b-card',[_c('h4',{staticClass:"font-weight-bold mb-50"},[_vm._v(" Комментарий ")]),_c('p',[_vm._v(_vm._s(item.text))]),_c('step-item',{staticClass:"mt-2",attrs:{"bordered":"","step":item.lessonStep}}),_c('div',{staticClass:"mt-1"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":toggleDetails}},[_vm._v(" Свернуть ")])],1)],1)]}}])}),_c('table-footer',{attrs:{"pagination-updated":_vm.getComments,"pagination":_vm.pagination}})],1)],1)],1),_c('confirm-modal',{ref:"deleteConfirm",attrs:{"yes":'Удалить',"color":"danger","text":"Выбранный комментарий будет удален."}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }