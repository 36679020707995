<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="mb-0"
        >
          <table-header
            search
            :filter="filter"
            :search-updated="getComments"
            date
          />
          <b-table
            ref="commentsTable"
            striped
            :items="comments"
            responsive
            :fields="tableFields"
            primary-key="id"
            show-empty
            :empty-text="$t('page.students.search.labels.no-items')"
            :sort-by.sync="filter.sortBy"
            :sort-desc.sync="filter.sortDesc"
          >
            <template #cell(user)="data">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <store-image
                  v-if="data.item.user.image"
                  :image-url="data.item.user.image.full_url"
                  wrapper-class="table-avatar"
                />
                <b-avatar
                  v-else
                  size="25px"
                />
                <span class="d-block ml-1">{{ data.value }}</span>
              </div>
            </template>
            <template #cell(actions)="{toggleDetails, detailsShowing, item}">
              <div class="d-flex flex-row justify-content-end align-items-center">
                <b-button
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  size="sm"
                  @click="toggleDetails"
                >
                  <feather-icon
                    size="15"
                    :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  />
                </b-button>
                <b-dropdown
                  size="sm"
                  variant="flat-secondary"
                  toggle-class="text-decoration-none btn-icon rounded-circle ml-1"
                  no-caret
                  boundary="viewport"
                >
                  <template #button-content>
                    <feather-icon
                      size="15"
                      icon="MoreVerticalIcon"
                    />
                  </template>
                  <b-dropdown-item @click="toggleDetails">
                    Подробнее
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'cabinet.step', params: { step_id: item.lesson_step_id }}">
                    Открыть карточку
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item
                    variant="danger"
                    @click="deleteComment(item.id)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                    />
                    <span>Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #row-details="{item, toggleDetails}">
              <b-card>
                <h4 class="font-weight-bold mb-50">
                  Комментарий
                </h4>
                <p>{{ item.text }}</p>
                <step-item
                  class="mt-2"
                  bordered
                  :step="item.lessonStep"
                />
                <div class="mt-1">
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleDetails"
                  >
                    Свернуть
                  </b-button>
                </div>
              </b-card>
            </template>
          </b-table>
          <table-footer
            :pagination-updated="getComments"
            :pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Выбранный комментарий будет удален.`"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BCol, BRow, BAvatar,
  BButton, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import { getUserFullName } from '@/helpers'
import ConfirmModal from '../../../components/page-elements/modals/ConfirmModal.vue'
import StepItem from '../../../components/steps/StepItem.vue'

export default {
  name: 'Comments',
  components: {
    StepItem,
    ConfirmModal,
    TableHeader,
    TableFooter,
    StoreImage,
    BCard,
    BTable,
    BCol,
    BRow,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data: () => ({
    comments: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    filter: {
      search: '',
      sortBy: 'created',
      sortDesc: true,
      date_range: '',
    },
  }),
  computed: {
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'user',
          label: 'Автор',
          sortable: false,
          formatter: (value, key, comment) => getUserFullName(comment.user),
        },
        {
          key: 'text',
          label: 'Комментарий',
          sortable: false,
        },
        {
          key: 'lessonStep.name',
          label: 'Название материала',
          sortable: false,
        },
        {
          key: 'created',
          label: 'Дата создания',
          sortable: true,
          formatter: (value, key, comment) => (comment.created ? this.$options.filters.dateTime(comment.created) : this.$t('no_data')),
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    sortBy() {
      this.getComments()
    },
  },
  async created() {
    await this.getComments()
  },
  methods: {
    async getComments() {
      const { items, _meta } = await this.$http.get('/step-comments', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          created: this.filter.date_range,
          expand: 'user.usersStudent,user.usersTeachers,user.image,lessonStep.stepElements.image,lessonStep.lessonStepsTags.tag,lessonStep.subject,lessonStep.grade',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.comments = items
    },
    async deleteComment(id) {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/step-comments/delete?id=${id}`)
              .then(() => {
                this.getComments()
              })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
